import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { LoadingComponent } from '../components/loading/loading.component';

defineLocale('es', esLocale);


@NgModule({
    imports: [
      CommonModule,
      NgxDatatableModule,
      FormsModule,
      ReactiveFormsModule,
      BsDropdownModule,
      BsDatepickerModule.forRoot(),
    ],
    declarations: [
      LoadingComponent
    ],
    providers: [BsDropdownConfig],
    exports: [
      CommonModule,
      NgxDatatableModule,
      FormsModule,
      ReactiveFormsModule,
      BsDatepickerModule,
      LoadingComponent
    ],
      entryComponents: []
  })
  
  export class SharedModule { }