import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AlertService, MessageSeverity} from './alert.service';
import {AppConstants} from "../constants/app.constants";

@Injectable({
  providedIn: 'root'
})
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(private alertService: AlertService) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        
        // Handle specific error structures first
        if (typeof error.error?.error === 'string' || error.error?.error instanceof String) {
          if(error.error?.error === 'unauthorized_client'){
            this.alertService.showMessage('', AppConstants.Messages.LOGIN_ERROR, MessageSeverity.error);
          }
        } else if (error.error?.errors && error.error?.errors?.messages && error.error?.errors?.messages.length > 0) {
          for (const message of error.error?.errors?.messages) {
            this.alertService.showMessage('', message, MessageSeverity.error);
          }
          return throwError(() => error);
        } else {
          // Handle based on HTTP status code
          switch (error.status) {
            case 0:
              errorMessage = 'Error de conexi�n: No se pudo conectar al servidor.';
              break;
            case 400:
              errorMessage = `Solicitud incorrecta: ${error.error?.message || 'Datos inv�lidos'}.`;
              break;
            case 401:
              errorMessage = 'No autorizado: Su sesi�n ha expirado. Por favor inicie sesi�n nuevamente.';
              // Auth interceptor will handle the redirect
              break;
            case 403:
              errorMessage = 'Acceso denegado: No tiene permisos para realizar esta acci�n.';
              break;
            case 404:
              errorMessage = 'No encontrado: El recurso solicitado no existe.';
              break;
            case 409:
              errorMessage = `Conflicto: ${error.error?.message || 'Ya existe un registro con estos datos'}.`;
              break;
            case 500:
              errorMessage = `Error del servidor: ${error.error?.message || 'Ocurri� un error interno en el servidor'}.`;
              break;
            default:
              errorMessage = `Error: ${error.error?.message || error.message || 'Ocurri� un error desconocido'}.`;
          }
          
          this.alertService.showMessage('', 'Ocurio un error por favor intentelo nuevamente.', MessageSeverity.error);
          //console.log('', 'Interceptor: ' + errorMessage + ' Status: ' + error.status + ' HTTP request failed: ' + error, MessageSeverity.error);
          
        }
        
        // Log the error for debugging
        console.error('Interceptor: ' + errorMessage + '\nStatus: ' + error.status + '\nHTTP request failed: ', error);
        
        // Return an observable with the error message
        return throwError(() => error);
      })
    );
  }
}
