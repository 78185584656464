// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url_base_onboarding:"https://service.onboarding.dev.bancoripley.com.pe",
  url_base: 'https://tinadminservices.dev.bancoripley.com.pe/admin',
  url_ef: 'https://apisecfraud.dev.bancoripley.com.pe/identity',
  ip:'https://api.ipify.org/?format=json',
  reCaptchaKey : '6LePgeIUAAAAAHOZ3ObQaVHcgCzEimjomrXdPIZ1'
};

