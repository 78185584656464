import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

  agencyCode: string = 'PE01254878';//sessionStorage.getItem(AppConstants.Session.AGENCYCODE);
  agencyName: string = 'Plaza Norte';//sessionStorage.getItem(AppConstants.Session.AGENCYNAME);
  usersessionId: string = 'jreyes';//sessionStorage.getItem(AppConstants.Session.USERID);
  channelName: string = 'otros';//sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION);

  constructor(private route: Router) {
  }

  ngOnInit() {
   //console.log("hhh");
  }
  
}
