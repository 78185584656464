import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OperationtypeService } from '../../services/operationtype.service';
import { FinancialEntityService } from '../../services/financialentity.service';
import { DocumentTypeService } from '../../services/documenttype.service';
import { TransferOrderOutService } from '../../services/transferorderout.service';
import { finalize, forkJoin } from 'rxjs';
import { Page } from 'src/app/models/page';
import { Utilities } from 'src/app/shared/helpers/util';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-consult-transfer-out',
  templateUrl: './consult-transfer-out.component.html',
  styleUrls: ['./consult-transfer-out.component.scss'],
})
export class ConsultTransferOutComponent implements OnInit {
  page: Page;
  loading: boolean = false;
  listOperationType: any[] = [];
  listStatus: any[] = [];
  listFinancialEntity: any[] = [];
  listTypeDocument: any[] = [];
  formFilter: FormGroup;
  rows: any[] = [];
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('moneyTemplate', { static: true }) moneyTemplate: TemplateRef<any>;
  columns: any[] = [];

  constructor(
    private fb: FormBuilder,
    private operationtypeService: OperationtypeService,
    private financialEntityService: FinancialEntityService,
    private documenttypeService: DocumentTypeService,
    private transferOrderOutService: TransferOrderOutService
  ) {
    this.formFilter = fb.group({
      startDate: [{ value: new Date(), disabled: false }, []],
      endDate: [{ value: new Date(), disabled: false }, []],
      state: [{ value: -1, disabled: false }, []],
      reference: [{ value: null, disabled: false }, []],
      transactionType: [{ value: -1, disabled: false }, []],
      entity: [{ value: -1, disabled: false }, []],
      documentType: [{ value: -1, disabled: false }, []],
      documentNumberOrigin: [{ value: null, disabled: false }, []],
      documentNumberDestiny: [{ value: null, disabled: false }, []],
      trace:  [{ value: null, disabled: false }, []],
      channel:  [{ value: null, disabled: false }, []],
    });
  }

  ngOnInit() {
    this.loading = true;

    this.page = {
      size: 10,
      pageNumber: 1,
      totalElements: 0,
      totalPages: 0,
      sort:'',
      sortType:''
    };

    const filter = this.formFilter.value;
    filter.pageNumber = this.page.pageNumber;
    filter.pageSize = this.page.size;

    forkJoin({
      operationtype: this.operationtypeService.GetALL(),
      financialEntity: this.financialEntityService.GetALL(),
      documentType: this.documenttypeService.GetALL(),
      status: this.transferOrderOutService.GetStatus(),
    })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((x) => {
        this.listFinancialEntity = x.financialEntity;
        this.listOperationType = x.operationtype;
        this.listTypeDocument = x.documentType;
        this.listStatus = x.status;
      });

    this.columns = [
      { prop: 'status', name: 'Transaccion', width: 100 },
      { name: 'Fecha/hora', width: 200, cellTemplate: this.dateTemplate },
      { prop: 'financialEntityCode', name: 'Entidad', width: 150 },
      { prop: 'queryInstructionId', name: 'Reference ID Con.', width: 250 },
      { prop: 'instructionId', name: 'Reference ID OT', width: 250 },
      { prop: 'trace', name: 'Trace', width: 150 },
      { prop: 'cciOrigin', name: 'CCI Origen', width: 180 },
      { prop: 'cciDestination', name: 'CCi Destino', width: 180 },
      { prop: 'currency', name: 'Moneda', width: 100 },
      { prop: 'amount', name: 'Importe', width: 100, cellTemplate: this.moneyTemplate },
      { prop: 'feeAmount',  name: 'Com. CC', width: 100, cellTemplate: this.moneyTemplate },
      { prop: 'feeAmountBank', name: 'Com. BRIP', width: 100, cellTemplate: this.moneyTemplate},
      { prop: 'feeCode', name: 'Cod. Tarifa', width: 100 },
      { prop: 'sameCustomerFlag', name: 'Cliente', width: 100 ,sortable: false},
      { prop: 'applicationCriteria', name: 'Plaza', width: 100,sortable: false },
      { prop: 'originDocumentNumber', name: 'Doc. Id.O', width: 150,sortable: false },
      { prop: 'originName', name: 'Originante', width: 250 ,sortable: false},
      { prop: 'destinyDocumentNumber', name: 'Doc. Id.B', width: 150 ,sortable: false},
      { prop: 'destinyName', name: 'Beneficiario', width: 250 ,sortable: false},
      { prop: 'channel', name: 'canal', width: 250 },
      { prop: 'settlementDate', name: 'F. Liquidación', width: 150 },
      { prop: 'responseCode', name: 'Código de respuesta', width: 150 },
      { prop: 'responseReason', name: 'Desc. de respuesta', width: 150 },
];
  }

  buscar(pageInfo: any = null,sortInfo: any = null): void {
    this.loading = true;
    const filter = this.formFilter.value;
    filter.pageSize = this.page.size;

    if (pageInfo == null) {
      filter.pageNumber = 1;
      this.page.pageNumber= 1;
    } else {
      filter.pageNumber = this.page.pageNumber;
    }

    if (sortInfo == null) {
      filter.order = this.page.sort;
      filter.orderType = this.page.sortType;
    } else {
      filter.order = sortInfo.prop;
      filter.orderType = sortInfo.dir;
    }


    if (filter.state == -1) filter.state = null;
    if (filter.transactionType == -1) filter.transactionType = null;
    if (filter.entity == -1) filter.entity = null;
    if (filter.documentType == -1) filter.documentType = null;

    this.transferOrderOutService
      .GetALL(filter)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((req) => {
        this.rows = [...req.results];
        this.page.totalElements = req.totalRows;
        this.page.totalPages = req.maxPage;
      });
  }
  setPage(pageInfo: any): void {
    this.page.pageNumber = pageInfo.offset + 1;
    this.buscar(pageInfo);
  }

  onSort(event) {
    const sort = event.sorts[0];
    this.page.sort = sort.prop;
    this.page.sortType = sort.dir;
    this.buscar(null, sort);
  }

  downloadExcel(): void {
    this.loading = true;
    const filter = this.formFilter.getRawValue();
    if (filter.state == -1) filter.state = null;
    if (filter.transactionType == -1) filter.transactionType = null;
    if (filter.entity == -1) filter.entity = null;
    if (filter.documentType == -1) filter.documentType = null;

    this.transferOrderOutService
      .getExport(filter)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((response: HttpResponse<Blob>) => {
        Utilities.downloadBlobFile(
          response.body as Blob,
          Utilities.getFileNameResponse(response)
        );
      });
  }
}
